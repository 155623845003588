import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { CellHyperlinkValue, CellRichTextValue, Workbook } from 'exceljs'
import Papa from 'papaparse'
import { Address, ContactSchema } from '../core/_models'
import z from 'zod'
import { importContact } from '../core/_requests'
import { useContactImport } from '../core/ContactImportProvider'

const ContactImportSchama = ContactSchema.extend({
    name: z.string().optional(),
    workEmail: z.string().optional(),
    phone: z.string().optional(),
    fax: z.string().optional(),
    website: z.string().optional(),
    facebook: z.string().optional(),
    linkedIn: z.string().optional(),
    instagram: z.string().optional(),
    email: z.string().optional(),
    mobileNumber: z.string().optional(),
    workNumber: z.string().optional(),
})

function isRichValue(value: any) {
    return Boolean(value && Array.isArray(value.richText))
}

type ContactImport = z.infer<typeof ContactImportSchama>

type tableDataType = {
    header: string[]
    data: ContactImport[]
}

function DropZone() {
    let [tableData, setTableData] = useState<tableDataType>({ header: [], data: [] })
    const { setState } = useContactImport()

    function readExcel(file: File) {
        console.log(file)

        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = async () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result as ArrayBuffer
            let workbook = new Workbook()

            await workbook.xlsx.load(binaryStr)

            // console.log(workbook)
            let worksheet = workbook.getWorksheet(1)
            const headerMap = new Map<number, keyof ContactImport>()

            const headers = worksheet.getRow(1).values as string[]

            headers.forEach((value: string, index: number) => {
                let key = value as string
                if (key === 'mobile number') {
                    key = 'mobileNumber'
                }
                if (key === 'MobileNumber') {
                    key = 'mobileNumber'
                }
                if (key === 'work number') {
                    key = 'workNumber'
                }
                if (key === 'Website') {
                    key = 'website'
                }
                if (key === 'Facebook') {
                    key = 'facebook'
                }
                if (key === 'Instagram') {
                    key = 'instagram'
                }
                if (key === 'LinkedIn') {
                    key = 'linkedIn'
                }

                console.log(key)
                try {
                    let validatedKey = ContactImportSchama.keyof().parse(key)
                    headerMap.set(index, validatedKey)
                } catch (e) {}
            })
            console.log(headerMap)
            let excelData: ContactImport[] = []
            worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                if (rowNumber === 1) {
                    return
                }

                // if (rowNumber >= 3) {
                //     return
                // }

                if (row == null || row === undefined) {
                    return
                }

                if (!row.values.entries == null || row.values.entries === undefined) {
                    return
                }

                let rowData: any = {}
                rowData['emails'] = []
                rowData['phones'] = []
                rowData['socialUrls'] = []
                rowData['address'] = [{ label: 'Home' }, { label: 'Work' }]
                // console.log(row)
                for (const [index, element] of (
                    row.values as string[] | CellHyperlinkValue[]
                ).entries()) {
                    // console.log(element)
                    // console.log(element instanceof Object && Object.hasOwn(element, 'richText'))
                    let key = headerMap.get(index)
                    if (key) {
                        if (element instanceof Object && Object.hasOwn(element, 'text')) {
                            rowData[key] = element.text
                        } else if (
                            element instanceof Object &&
                            Object.hasOwn(element, 'richText')
                        ) {
                            rowData[key] = (element as unknown as CellRichTextValue).richText
                                .map(({ text }) => text)
                                .join('')
                        } else {
                            rowData[key] = element
                        }

                        if (key === 'address') {
                            rowData[key] = [
                                { label: 'Home' },
                                { label: 'Work', street: rowData[key] },
                            ]
                        }

                        if (key === 'name') {
                            rowData['firstName'] = rowData[key]
                        }

                        // if (key === 'note') {
                        //     rowData['note'] = rowData[key]
                        // }

                        if (key === 'workEmail') {
                            rowData['emails'] = [
                                ...rowData['emails'],
                                { label: 'Work', value: rowData[key] },
                            ]
                        }
                        if (key === 'email') {
                            rowData['emails'] = [
                                ...rowData['emails'],
                                { label: 'Work', value: rowData[key] },
                            ]
                        }

                        // if (key === 'phone' && rowData[key] !== '') {
                        //     rowData['phones'] = [
                        //         ...rowData['phones'],
                        //         { label: 'Mobile', value: rowData[key] },
                        //     ]
                        // }
                        if (key === 'mobileNumber') {
                            rowData['phones'] = [
                                ...rowData['phones'],
                                { label: 'Mobile', value: rowData[key] },
                            ]
                        }
                        if (key === 'workNumber') {
                            rowData['phones'] = [
                                ...rowData['phones'],
                                { label: 'Work', value: rowData[key] },
                            ]
                        }
                        if (key === 'fax') {
                            rowData['phones'] = [
                                ...rowData['phones'],
                                { label: 'fax', value: rowData[key] },
                            ]
                        }
                        if (key === 'website') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Website', value: rowData[key] },
                            ]
                        }
                        if (key === 'facebook') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Facebook', value: rowData[key] },
                            ]
                        }
                        if (key === 'linkedIn') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'LinkedIn', value: rowData[key] },
                            ]
                        }
                        if (key === 'instagram') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Instagram', value: rowData[key] },
                            ]
                        }
                    }
                }
                // console.log(rowData)
                let validatedData = ContactImportSchama.safeParse(rowData)

                if (validatedData.success) {
                    if (Object.keys(rowData).length && validatedData.data.firstName) {
                        excelData.push(validatedData.data)
                    }
                } else {
                    console.log(validatedData.error)
                    if (Object.keys(rowData).length && rowData['firstName']) {
                        excelData.push(rowData as ContactImport)
                    }
                }
            })

            const newTableData = { header: [...headerMap.values()], data: excelData }
            console.log(newTableData)
            setTableData(newTableData)
        }
        reader.readAsArrayBuffer(file)
    }

    function readCSV(file: File) {
        console.log(file)

        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = async () => {
            // Do whatever you want with the file contents
            const text = reader.result as string

            let result = Papa.parse(text, { skipEmptyLines: true }) as Papa.ParseResult<string[]>

            let headers = result.data[0]
            const headerMap = new Map<number, keyof ContactImport>()

            headers.forEach((value: string, index: number) => {
                let key = value as string
                try {
                    let validatedKey = ContactImportSchama.keyof().parse(key)
                    headerMap.set(index, validatedKey)
                } catch (e) {}
            })

            let dataList: ContactImport[] = []

            result.data.slice(1).forEach((row) => {
                let rowData: any = {}
                rowData['emails'] = []
                rowData['phones'] = []
                rowData['socialUrls'] = []

                for (const [index, element] of row.entries()) {
                    let key = headerMap.get(index)
                    if (key) {
                        if (key === 'address') {
                            continue
                        }
                        rowData[key] = element

                        if (key === 'workEmail') {
                            rowData['emails'] = [{ label: 'Work', value: rowData[key] }]
                        }
                        if (key === 'phone') {
                            rowData['phones'] = [{ label: 'Mobile', value: rowData[key] }]
                        }
                        if (key === 'fax') {
                            rowData['phones'] = [
                                ...rowData['phones'],
                                { label: 'fax', value: rowData[key] },
                            ]
                        }
                        if (key === 'website') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Website', value: rowData[key] },
                            ]
                        }
                        if (key === 'facebook') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Facebook', value: rowData[key] },
                            ]
                        }
                        if (key === 'linkedIn') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'LinkedIn', value: rowData[key] },
                            ]
                        }
                        if (key === 'instagram') {
                            rowData['socialUrls'] = [
                                ...rowData['socialUrls'],
                                { label: 'Instagram', value: rowData[key] },
                            ]
                        }
                    }
                }
                let validatedData = ContactImportSchama.safeParse(rowData)

                if (validatedData.success) {
                    if (Object.keys(rowData).length && validatedData.data.firstName) {
                        dataList.push(validatedData.data)
                    }
                } else {
                    console.log(validatedData.error)
                    if (Object.keys(rowData).length && rowData['firstName']) {
                        dataList.push(rowData as ContactImport)
                    }
                }
            })
            const newTableData = { header: [...headerMap.values()], data: dataList }

            console.log(newTableData)
            setTableData(newTableData)
        }
        reader.readAsText(file)
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            console.log(file)
            console.log(file.type)
            let matchExtension = file.name.match(/\.[0-9a-z]+$/i)
            let extension = matchExtension ? matchExtension[0] : ''
            console.log(extension)
            if (extension === '.csv') {
                readCSV(file)
            }
            if (extension === '.xlsx') {
                readExcel(file)
            }
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    return (
        <div>
            <div
                className='tw-w-full tw-h-full tw-text-center tw-border-dashed tw-rounded-lg tw-border-slate-400 tw-border-2'
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <p className='tw-flex tw-items-center tw-justify-center tw-place-content-center tw-object-center'>
                    Drag 'n' drop some files here, or click to select files
                </p>
                {/* <table className='tw-table'> */}
            </div>
            {tableData.header.length > 0 && (
                <>
                    <div className='tw-overflow-x-scroll tw-overscroll-auto'>
                        <table
                            id='kt_table_contacts_import'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer tw-max-w-full tw-max-h-full '
                        >
                            <thead>
                                <tr className='text-start   fw-bolder fs-7 text-uppercase gs-0'>
                                    {tableData.header.map((column) => (
                                        <th>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='tw-overflow-y-scroll tw-overscroll-auto'>
                                {tableData.data.length > 0 ? (
                                    tableData.data.map((contact) => (
                                        <tr className='text-start text-muted  fs-7 gs-0'>
                                            {tableData.header.map((column) => {
                                                let key = column as keyof ContactImport
                                                let cellData = contact[key] ? contact[key] : ''
                                                // console.log(key)
                                                // console.log(cellData)
                                                let data = cellData as string
                                                if (key === 'address') {
                                                    data = (cellData as Address[])[1]
                                                        .street as string
                                                }
                                                return <td className='tw-p-1'>{data}</td>
                                            })}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                No matching records found
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='tw-flex tw-gap-2 tw-justify-end tw-pt-2'>
                        <button
                            className='btn btn-light '
                            onClick={() => {
                                setTableData({ header: [], data: [] })
                            }}
                        >
                            Discard
                        </button>
                        <button
                            className='btn btn-primary'
                            onClick={async () => {
                                if (tableData.data.length) {
                                    //submit
                                    await importContact(tableData.data)
                                    setState(false)
                                }
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export { DropZone }
