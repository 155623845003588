import { FC, useState } from 'react'
import * as Yup from 'yup'
import SVG from 'react-inlinesvg'
import { FormikProps, useFormik } from 'formik'
import { isNotEmpty, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { initialContact, Contact, Address, UploadedFile, Email } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import {
    createContact,
    getPhotoLink,
    updateContact,
    updatePhoto,
    uploadPhoto,
} from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { ContactsListLoading } from '../components/loading/ContactsListLoading'
import Accordion from 'react-bootstrap/Accordion'
import { Alert, Dropdown, DropdownButton } from 'react-bootstrap'
import { ContextReplacementPlugin } from 'webpack'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useOrgSelection } from '../../../../context/OrgContext'
import { Axios, AxiosError } from 'axios'
import { parseSize } from '../../../../util/tool'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DropZone } from './DropZone'


const API_URL = process.env.REACT_APP_API_URL
const VCF_URL = `${API_URL}/file`
const FILE_SIZE_LIMIT = process.env.PHOTO_FILE_SIZE ? process.env.PHOTO_FILE_SIZE : '1MB'

type Props = {
    isContactLoading: boolean
    contact: Contact
}

const ContactImportModalForm: FC<Props> = ({ contact, isContactLoading }) => {
    return (
        <>
            <DropZone></DropZone>
        </>
    )
}
const DateField = (formik: FormikProps<Contact>, field: string) => {
    const years = range(1950, new Date().getFullYear() + 1)
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    return (
        <DatePicker
            isClearable={true}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <button
                        type='button'
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                    >
                        {'<'}
                    </button>
                    <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button
                        type='button'
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                    >
                        {'>'}
                    </button>
                </div>
            )}
            className={'form-control form-control-solid mb-3 mb-lg-0'}
            {...formik.getFieldProps(field)}
            selected={
                formik.getFieldProps(field).value
                    ? new Date(formik.getFieldProps(field).value)
                    : null
            }
            dateFormat='dd/MM/yyyy'
            onChange={(date) => formik.setFieldValue(field, date)}
        />
    )
}
const range = (start: number, end: number) => {
    return Array.from(Array(end - start + 1).keys()).map((x) => x + start)
}
export { ContactImportModalForm }
