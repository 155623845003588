import axios, { AxiosResponse } from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const VISIT_URL = `${API_URL}/visit`

type VisitCountType = {
    date: string
    count: number
}

type RankCountType = {
    id: string
    name: string
    photo: string
    count: number
}
type StatisticDataType = {
    count: {
        day: VisitCountType[]
        month: VisitCountType[]
        year: VisitCountType[]
    }
    rank: {
        contact: RankCountType[]
    }
}

// const getVisitDay = (): Promise<VisitCountType[]> => {
//     return axios
//         .get(`${VISIT_URL}/count/day`)
//         .then((response: AxiosResponse<VisitCountType[]>) => response.data)
// }

// const getVisitMonth = (): Promise<VisitCountType[]> => {
//     return axios
//         .get(`${VISIT_URL}/count/month`)
//         .then((response: AxiosResponse<VisitCountType[]>) => response.data)
// }

// const getVisitYear = (): Promise<VisitCountType[]> => {
//     return axios
//         .get(`${VISIT_URL}/count/year`)
//         .then((response: AxiosResponse<VisitCountType[]>) => response.data)
// }

// const getVisitRankContact = (): Promise<RankCountType[]> => {
//     return axios
//         .get(`${VISIT_URL}/rank/contact`)
//         .then((response: AxiosResponse<RankCountType[]>) => response.data)
// }

// const getVisitRankUser = (): Promise<RankCountType[]> => {
//     return axios
//         .get(`${VISIT_URL}/rank/user`)
//         .then((response: AxiosResponse<RankCountType[]>) => response.data)
// }

const getAdminStatistic = (): Promise<StatisticDataType> => {
    return axios
        .get(`${VISIT_URL}/admin/statistic`)
        .then((response: AxiosResponse<StatisticDataType>) => response.data)
}

const getUserStatistic = (): Promise<StatisticDataType> => {
    return axios
        .get(`${VISIT_URL}/user/statistic`)
        .then((response: AxiosResponse<StatisticDataType>) => response.data)
}

export { getAdminStatistic, getUserStatistic }
export type { VisitCountType, RankCountType, StatisticDataType }
