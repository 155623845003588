import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Contact, ContactsQueryResponse, ORG, UploadedFile } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CONTACT_URL = `${API_URL}/contact`
const IMPORT_CONTACT_URL = `${API_URL}/contact/bulk`
const VCF_URL = `${API_URL}/contact/vcard`
const GET_CONTACTS_URL = `${API_URL}/contact`
const UPLOAD_PHOTO_URL = `${API_URL}/file/upload/photo`
const PHOTO_URL = `${API_URL}/file/photo`
const ORG_URL = `${API_URL}/organisation`

const getContacts = (query: string): Promise<ContactsQueryResponse> => {
    return axios
        .get(`${GET_CONTACTS_URL}?${query}`)
        .then((d: AxiosResponse<ContactsQueryResponse>) => d.data)
}

const getContactById = (id: ID): Promise<Contact | undefined> => {
    return axios
        .get(`${CONTACT_URL}/${id}`)
        .then((response: AxiosResponse<Response<Contact>>) => response.data)
        .then((response: Response<Contact>) => response.data)
}

const uploadPhoto = (file: File): Promise<UploadedFile | undefined> => {
    let formData = new FormData()
    formData.append('photo', file)

    return axios
        .post(`${UPLOAD_PHOTO_URL}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response: AxiosResponse<UploadedFile>) => response.data)
}

const updatePhoto = (id: ID, uploadFileId: string): Promise<Contact | undefined> => {
    return axios
        .put(`${CONTACT_URL}/${id}/photo`, { uploadFileId: uploadFileId })
        .then((response: AxiosResponse<Response<Contact>>) => response.data)
        .then((response: Response<Contact>) => response.data)
}

const getPhotoLink = (filename: string): string => {
    return PHOTO_URL + '/' + filename
}

const createContact = (contact: Contact): Promise<Contact | undefined> => {
    return axios
        .post(CONTACT_URL, contact)
        .then((response: AxiosResponse<Response<Contact>>) => response.data)
        .then((response: Response<Contact>) => response.data)
}

const importContact = (contacts: Contact[]): Promise<Contact | undefined> => {
    return axios
        .post(IMPORT_CONTACT_URL, contacts)
        .then((response: AxiosResponse<Response<Contact>>) => response.data)
        .then((response: Response<Contact>) => response.data)
}

const updateContact = (contact: Contact): Promise<Contact | undefined> => {
    return axios.put(`${CONTACT_URL}/${contact.id}`, contact)
}

const reactivateContact = (contactId: ID): Promise<string> => {
    return axios
        .put(`${CONTACT_URL}/${contactId}/reactivate`)
        .then((data: AxiosResponse<string>) => data.data)
}

const deleteContact = (contactId: ID): Promise<void> => {
    return axios.delete(`${CONTACT_URL}/${contactId}`).then(() => {})
}

const deleteSelectedContacts = (contactIds: Array<ID>): Promise<void> => {
    const requests = contactIds.map((id) => axios.delete(`${CONTACT_URL}/${id}`))
    return axios.all(requests).then(() => {})
}

const getOrganisation = (): Promise<any> => {
    return axios
        .get(`${ORG_URL}?`)
        .then((d: AxiosResponse<Response<ORG[]>>) => d.data)
        .then((d: Response<ORG[]>) => d.data)
}

export {
    getContacts,
    reactivateContact,
    deleteContact,
    deleteSelectedContacts,
    getContactById,
    createContact,
    importContact,
    updateContact,
    uploadPhoto,
    updatePhoto,
    getPhotoLink,
    getOrganisation,
}
