/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from 'react-router-dom'
import { ShareContactCard } from './components/ShareContactCard'
import { ShareContactLayout } from './ShareContactLayout'

const ShareContactPage = () => (
    <Routes>
        <Route element={<ShareContactLayout />}>
            <Route path=':contactId' element={<ShareContactCard />} />
        </Route>
    </Routes>
)

export { ShareContactPage }
