import { FC, useEffect, useState } from 'react'
import { ContactRank } from './components/ContactRank'
import { VisitCountMonth } from './components/VisitCountMonth'
import { StatisticDataType, getAdminStatistic, getUserStatistic } from './core/_requests'

const defaultData = {
    count: { day: [], month: [], year: [] },
    rank: { contact: [] },
}

const StatisticsAdmin: React.FC = () => {
    const [data, setData] = useState<StatisticDataType>(defaultData)
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        const chart = () => {
            if (!loaded) {
                getAdminStatistic().then((resp) => {
                    console.log(resp)
                    if (resp) {
                        setData(resp)
                    }
                    setLoaded(true)
                })
            }
        }

        chart()
    }, [data, loaded])
    console.log(data)
    return (
        <>
            {/* begin::Row */}
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-4'>
                    <VisitCountMonth
                        className='card-xl-stretch mb-xl-8'
                        // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                        data={data?.count?.day ? data.count.day : []}
                        color='info'
                        description='Day'
                        // change='256'
                    />
                </div>

                <div className='col-xl-4'>
                    <VisitCountMonth
                        className='card-xl-stretch mb-xl-8'
                        // svgIcon='/media/icons/duotune/general/gen025.svg'
                        data={data?.count?.month ? data.count.month : []}
                        color='success'
                        description='Month'
                        // change='1000'
                    />
                </div>

                <div className='col-xl-4'>
                    <VisitCountMonth
                        className='card-xl-stretch mb-5 mb-xl-8'
                        // svgIcon='/media/icons/duotune/finance/fin006.svg'
                        data={data?.count?.year ? data.count.year : []}
                        color='primary'
                        description='Year'
                        // change='2000'
                    />
                </div>
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row g-5 g-xl-8'>
                {/* begin::Col */}
                <div className='col-xl-12'>
                    <ContactRank
                        title='Most View Contact'
                        subtitle=''
                        className='card-xl-stretch mb-5 mb-xl-8'
                        data={data?.rank?.contact ? data.rank.contact : []}
                    />
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                {/* <div className='col-xl-6'>
                    <UserRank className='card-xl-stretch mb-5 mb-xl-8' />
                </div> */}
                {/* end::Col */}
            </div>
            {/* end::Row */}
        </>
    )
}

export { StatisticsAdmin }
