import clsx from 'clsx'
import { getPhotoLink } from '../../../../app/modules/apps/contact-management/contacts-list/core/_requests'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
    HeaderNotificationsMenu,
    HeaderUserMenu,
    Search,
    ThemeModeSwitcher,
} from '../../../partials'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
    const { currentUser } = useAuth()

    return (
        <div className='app-navbar flex-shrink-0'>
            {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
                <Search />
            </div> */}

            {/* <div className={clsx('app-navbar-item', itemClass)}>
                <div id='kt_activities_toggle' className={btnClass}>
                    <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className={btnIconClass}
                    />
                </div>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    className={btnClass}
                >
                    <KTSVG
                        path='/media/icons/duotune/general/gen022.svg'
                        className={btnIconClass}
                    />
                </div>
                <HeaderNotificationsMenu />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com012.svg'
                        className={btnIconClass}
                    />
                    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
                </div>
            </div> */}

            <div className={clsx('app-navbar-item', itemClass)}>
                <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                    <img src={getPhotoLink(currentUser?.photo + '')} alt='' />
                </div>
                <HeaderUserMenu />
            </div>
        </div>
    )
}

export { Navbar }
