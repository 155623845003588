import { useEffect, useState } from 'react'
import { Dropdown, Modal, Popover, SplitButton } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import { useNavigate, useParams } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Contact } from '../../apps/contact-management/contacts-list/core/_models'
import {
    getContactById,
    getPhotoLink,
} from '../../apps/contact-management/contacts-list/core/_requests'
import { ShareContactLoading } from './ShareContactLoading'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import { cn } from '../../../../lib/util'

// const contact = {
//     contactId: 0,
//     name: 'Simon',
//     organisation: 'Demo Sdn Bhd',
//     title: 'CEO',
//     email: 'Simon@demo.com',
//     phone: '012-1234567',
// }

const API_URL = process.env.REACT_APP_API_URL
const VCF_URL = `${API_URL}/contact/vcard/`
const EXPORT_SVG_VCF_URL = `${API_URL}/contact/export-svg-vcard/`
const EXPORT_VCF_URL = `${API_URL}/contact/export-vcard/`
const PRIMARY_COLOR = '#010a2d'

// const PRIMARY_COLOR = '#803815'
const THEME_COLOR = '#db9b30'
const THEME_SHADE_COLOR = '#4b2d0b'

const popover = (
    <Popover>
        <Popover.Body>Copied to clipboard</Popover.Body>
    </Popover>
)

const ShareContactCard = () => {
    let { contactId } = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(true)
    const [isCopy, setCopy] = useState(false)
    const [svgContact, setSvgContact] = useState('')
    const [contact, setContact] = useState<Contact>({
        firstName: '',
        lastName: '',
        organisation: '',
        title: '',
        emails: [{ label: 'Home', value: 'Na' }],
        phones: [{ label: 'Home', value: 'Na' }],
        photo: '',
        background: '',
        logo: '',
    })
    const copy = (e: any) => {
        window.navigator['clipboard'].writeText(window.location.href).then(() => {
            setCopy(true)
            setTimeout(() => setCopy(false), 2000)
        })
    }
    useEffect(() => {
        const getContact = async () => {
            let contact = await getContactById(contactId).catch((e) => navigate('/error/404'))
            setLoading(false)
            if (contact?.id) {
                setContact(contact)
            } else {
                navigate('/error/404')
            }
        }

        const fetchImage = async (contactId: string) => {
            return fetch(EXPORT_SVG_VCF_URL + contactId, {
                method: 'GET',
            })
                .then(async (response) => setSvgContact(await response.text()))

                .catch((error) => console.error(error))
        }

        getContact()
        fetchImage(contactId ? contactId : '')
    }, [])

    console.log(contact)
    const addressList =
        Array.isArray(contact.address) && contact.address.length
            ? contact.address.map((add) => {
                  let address = ''
                  address += add.street ? `${add.street} ,` : ''
                  address += add.city ? `${add.city} ,` : ''
                  address += add.stateProvince ? `${add.stateProvince} ,` : ''
                  address += add.country ? `${add.country} ,` : ''
                  address += add.extendedAddress ? `${add.extendedAddress} ,` : ''
                  address = address.slice(0, -2)
                  return { label: add.label, link: add.link, address: address }
              })
            : undefined

    const workAddress = addressList
        ? addressList.find((add) => add.label && add.label.toUpperCase() === 'WORK')
        : undefined

    const otherAddress = addressList
        ? addressList.find((add) => add.address && add.address.trim() !== '')
        : undefined

    let address = workAddress?.address ? workAddress : otherAddress

    let name = contact?.firstName ? contact?.firstName : ''
    name = contact?.lastName ? `${name} ${contact?.lastName}` : name

    const website =
        Array.isArray(contact.socialUrls) && contact.socialUrls.length
            ? contact.socialUrls[0].value
            : ''
    const email =
        Array.isArray(contact.emails) && contact.emails.length ? contact.emails[0].value : ''
    const phone =
        Array.isArray(contact.phones) && contact.phones.length ? contact.phones[0].value : ''

    return (
        <div className=' tw-flex tw-flex-col tw-min-h-screen'>
            <div className='' style={{ backgroundColor: PRIMARY_COLOR }}>
                <div className='tw-flex tw-justify-center'>
                    <div
                        className={cn([
                            "'md:tw-pt-16 md:tw-pl-32 md:tw-pr-32 tw-pt-16 tw-pl-16 tw-pr-16",
                            'tw-min-w-full',
                        ])}
                        style={{ backgroundColor: PRIMARY_COLOR }}
                    >
                        <img
                            className='tw-min-w-full'
                            src={
                                isLoading
                                    ? toAbsoluteUrl('/media/avatars/blank.png')
                                    : contact.photo
                                    ? getPhotoLink(contact.photo + '')
                                    : toAbsoluteUrl('/media/profile/default.jpg')
                            }
                            alt=''
                        />
                    </div>
                </div>
                {/* end::Illustration */}

                {/* <div className='tw-flex tw-justify-center tw-text-center '>
                    <p className='text-gray-800 fs-3 fw-bolder'>
                        {contact?.organisation ? contact?.organisation : ''}
                    </p>
                </div> */}

                <div id='DetailPanel' className='tw-flex tw-justify-center '>
                    <div className='tw-grid tw-grid-cols-10 tw-text-white tw-place-content-center tw-content-start tw-w-4/5 tw-font-sans  tw-justify-center tw-text-lg md:tw-text-lg '>
                        <div className='tw-flex tw-justify-center tw-pl-4 tw-py-1 tw-col-span-10 '>
                            <h1
                                className={cn([
                                    'tw-font-playfair tw-font-extrabold  tw-w-full tw-leading-tight tw-m-0 ',
                                    'tw-text-transparent tw-bg-clip-text ',
                                    'tw-text-center',
                                    // 'tw-text-justify tw-overflow-hidden  tw-h-20 ',
                                    // 'after:tw-content[""] after:tw-inline-block after:tw-w-full',
                                ])}
                                style={{
                                    textJustify: 'inter-character',
                                    fontSize: '2.5rem',
                                    backgroundImage: `linear-gradient(45deg, ${THEME_SHADE_COLOR} 0%, ${THEME_SHADE_COLOR} 0%, ${THEME_COLOR} 40%, ${THEME_COLOR} 60% , ${THEME_SHADE_COLOR} 100%, ${THEME_SHADE_COLOR} 100% )`,
                                }}
                            >
                                {name ? name : ''}
                            </h1>
                        </div>

                        <div className='tw-flex tw-justify-center tw-pl-4 tw-col-span-10 tw-pb-20 '>
                            <p className='tw-text-center tw-font-playfair text-white tw-text-2xl fw-bolder m-0'>
                                {contact?.title ? contact?.title : ''}
                            </p>
                        </div>

                        <div className='tw-flex tw-justify-start tw-p-4 tw-col-span-2 tw-aspect-square'>
                            <img
                                className=''
                                // style={{ minWidth: 150, minHeight: 150, width: '100%' }}
                                src={toAbsoluteUrl('/media/ashvin/Icon 1.png')}
                                alt=''
                            />
                        </div>

                        <div className='tw-flex tw-justify-start tw-pl-1 tw-col-span-8'>
                            <div className='tw-flex tw-flex-row tw-justify-center tw-content-center tw-items-center'>
                                {address?.link ? (
                                    <a
                                        href={address.link ? `${address.link}` : ''}
                                        style={{
                                            fontSize: '10pt',
                                            lineHeight: '2.6vh',
                                        }}
                                        className=' text-left  tw-break-words tw-break-all tw-text-white'
                                    >
                                        {address.address}
                                    </a>
                                ) : (
                                    <p
                                        style={{
                                            fontSize: '10pt',
                                            lineHeight: '2.6vh',
                                        }}
                                        className=' text-left  tw-break-words tw-break-all'
                                    >
                                        {address ? address.address : ''}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className='tw-flex tw-justify-start tw-p-4 tw-col-span-2 tw-aspect-square'>
                            <img
                                className=''
                                // style={{ minWidth: 150, minHeight: 150, width: '100%' }}
                                src={toAbsoluteUrl('/media/ashvin/Icon 2.png')}
                                alt=''
                            />
                        </div>

                        <div className='tw-flex tw-justify-start tw-pl-1 tw-col-span-8 '>
                            <div className='tw-flex tw-flex-row tw-justify-center tw-content-center tw-items-center'>
                                <a
                                    href={website ? `${website}` : ''}
                                    className=' text-left tw-text-white text-hover-primary tw-break-words tw-break-all'
                                >
                                    {website ? website : ''}
                                </a>
                            </div>
                        </div>

                        <div className='tw-flex tw-justify-start tw-p-4 tw-col-span-2 tw-aspect-square'>
                            <img
                                className=''
                                // style={{ minWidth: 150, minHeight: 150, width: '100%' }}
                                src={toAbsoluteUrl('/media/ashvin/Icon 3.png')}
                                alt=''
                            />
                        </div>

                        <div className='tw-flex tw-justify-start tw-pl-1 tw-col-span-8'>
                            <div className='tw-flex tw-flex-row tw-justify-center tw-content-center tw-items-center'>
                                <a
                                    href={email ? `mailto:${email}` : ''}
                                    className=' text-left tw-text-white text-hover-primary tw-break-words tw-break-all'
                                >
                                    {email ? email : 'No Email'}
                                </a>
                            </div>
                        </div>

                        <div className='tw-flex tw-justify-start tw-p-4 tw-col-span-2 tw-aspect-square'>
                            <img
                                className=''
                                // style={{ minWidth: 150, minHeight: 150, width: '100%' }}
                                src={toAbsoluteUrl('/media/ashvin/Icon 4.png')}
                                alt=''
                            />
                        </div>

                        <div className='tw-flex tw-justify-start tw-pl-1 tw-col-span-8'>
                            <div className='tw-flex tw-flex-row tw-justify-center tw-content-center tw-items-center'>
                                <a
                                    href={phone ? `tel:${phone}` : ''}
                                    className=' text-left tw-text-white text-hover-primary tw-break-words tw-break-all'
                                >
                                    {phone ? phone : 'Not Contact'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className=' tw-pb-20 tw-pt-20 tw-flex-auto tw-flex tw-flex-col tw-items-center tw-justify-center'
                style={{
                    objectFit: 'cover',
                    backgroundImage: `linear-gradient(to bottom, ${PRIMARY_COLOR} 0%, ${PRIMARY_COLOR} 0%, rgba(255,0,0,0) 20%, rgba(255,0,0,0) 80% , ${PRIMARY_COLOR} 100%, ${PRIMARY_COLOR} 100% ), url(${
                        contact.background
                            ? getPhotoLink(contact.background + '')
                            : toAbsoluteUrl('/media/background/gold_leaf.jpg')
                    })`,
                }}
            >
                <div
                    id='QR Section'
                    className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full'
                >
                    <div className='tw-p-4 tw-rounded tw-bg-white tw-flex-auto tw-w-1/2 tw-aspect-square'>
                        <img src={`data:image/svg+xml;utf8,${svgContact}`} alt='Contact QR SVG' />
                    </div>
                    <div className='tw-flex-auto tw-pt-8 tw-flex tw-gap-4'>
                        <button
                            style={{ color: PRIMARY_COLOR, backgroundColor: THEME_COLOR }}
                            className=' tw-rounded tw-p-4 tw-font-bold'
                            onClick={copy}
                        >
                            Share Link
                        </button>
                        <Modal show={isCopy} animation={true}>
                            <Modal.Body className='text-center'>Copied to clipboard</Modal.Body>
                        </Modal>
                        <div
                            className=' tw-rounded  tw-font-bold tw-flex'
                            style={{ color: PRIMARY_COLOR }}
                        >
                            <DropdownMenu>
                                <a
                                    style={{
                                        color: PRIMARY_COLOR,
                                        borderColor: PRIMARY_COLOR,
                                        backgroundColor: THEME_COLOR,
                                    }}
                                    href={VCF_URL + contactId}
                                    className='tw-border-r-2  tw-p-4 tw-rounded-l'
                                >
                                    Save Contact
                                </a>
                                <DropdownMenuTrigger
                                    style={{
                                        color: PRIMARY_COLOR,
                                        borderColor: PRIMARY_COLOR,
                                        backgroundColor: THEME_COLOR,
                                    }}
                                    className=' tw-p-4 tw-rounded-r'
                                >
                                    ▼
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align='end'
                                    style={{
                                        color: PRIMARY_COLOR,
                                        borderColor: PRIMARY_COLOR,
                                        backgroundColor: THEME_COLOR,
                                    }}
                                    className='tw-flex tw-mt-2 tw-flex-col tw-rounded-lg tw-min-w-150 tw-py-1'
                                >
                                    <a
                                        style={{ color: PRIMARY_COLOR }}
                                        className='tw-px-4 tw-py-1 hover:tw-bg-white hover:tw-opacity-30'
                                        href={EXPORT_VCF_URL + contactId + '/svg'}
                                    >
                                        .svg
                                    </a>
                                    <a
                                        style={{ color: PRIMARY_COLOR }}
                                        className='tw-px-4 tw-py-1 hover:tw-bg-white hover:tw-opacity-30'
                                        href={EXPORT_VCF_URL + contactId + '/pdf'}
                                    >
                                        .pdf
                                    </a>
                                    <a
                                        style={{ color: PRIMARY_COLOR }}
                                        className='tw-px-4 tw-py-1 hover:tw-bg-white hover:tw-opacity-30'
                                        href={EXPORT_VCF_URL + contactId + '/eps'}
                                    >
                                        .eps
                                    </a>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div
                    className='tw-flex tw-justify-center tw-p-8 '
                    style={{ backgroundColor: PRIMARY_COLOR }}
                >
                    <img
                        className='mw-100 tw-w-2/6'
                        // style={{ minWidth: 150, minHeight: 150, width: '100%' }}
                        src={
                            contact.logo
                                ? getPhotoLink(contact.logo + '')
                                : toAbsoluteUrl('/media/to1card/to1card.png')
                        }
                        alt='Metornic'
                    />
                </div>
            </div>
            {isLoading && <ShareContactLoading />}
            {/* begin::Link */}
            {/* <div className='mb-0'>
                <Link to='/dashboard' className='btn btn-sm btn-primary'>
                    Return Home
                </Link>
            </div> */}
            {/* end::Link */}
        </div>
    )
}
export { ShareContactCard }
