/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { ShareContactPage } from '../modules/share-contact/ShareContactPage'
import { ContactImportModalForm } from '../modules/apps/contact-management/contacts-list/contact-import-modal/ContactImportModalForm'

const { PUBLIC_URL } = process.env
const disableAuth = false

const AppRoutes: FC = () => {
    const { currentUser } = useAuth()
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='share-contact/*' element={<ShareContactPage />} />
                    <Route
                        path='test/*'
                        element={<ContactImportModalForm isContactLoading={false} contact={{}} />}
                    />
                    <Route path='logout' element={<Logout />} />
                    {/* <Route path='vcard' element={<Logout />} /> */}
                    {currentUser || disableAuth ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/dashboard' />} />
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route
                                path='test/*'
                                element={
                                    <ContactImportModalForm isContactLoading={false} contact={{}} />
                                }
                            />
                            <Route path='*' element={<Navigate to='/auth' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }
