/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { Alert } from 'react-bootstrap'
import { AxiosError } from 'axios'

const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmpassword: '',
    acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    confirmpassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref('password')],
                "Password and Confirm Password didn't match"
            ),
        }),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
    const [loading, setLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                await register(values.name, values.email, values.password, values.confirmpassword)
                setSuccess(true)
                setTimeout(() => navigate('/auth/login', {}), 1000)

                // const { data: user } = await getUserByToken()
                // setCurrentUser(user)
            } catch (error) {
                let err = error as AxiosError
                console.log(err.response)
                let errMsg = err.response?.data.errorMessage
                saveAuth(undefined)
                setStatus(errMsg)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
                {/* end::Title */}

                {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
            </div>
            {/* end::Heading */}

            {/* begin::Login options */}
            {/* <div className='row g-3 mb-9'>
                <div className='col-md-6'>
                    <a
                        href='#'
                        className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                            className='h-15px me-3'
                        />
                        Sign in with Google
                    </a>
                </div>
                <div className='col-md-6'>
                    <a
                        href='#'
                        className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                            className='theme-light-show h-15px me-3'
                        />
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                            className='theme-dark-show h-15px me-3'
                        />
                        Sign in with Apple
                    </a>
                </div>
            </div> */}
            {/* end::Login options */}

            {/* <div className='separator separator-content my-14'> */}
            <div className='separator my-14'>
                {/* <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span> */}
            </div>

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}
            {isSuccess && (
                // <div className='mb-lg-15 alert alert-danger'>
                //     <div className='alert-text font-weight-bold'></div>
                // </div>
                <Alert variant='success' className='text-center'>
                    Account Successfully Created
                </Alert>
            )}

            {/* <Alert variant='success' className='text-center'>
                {formik.errors.name?.toString()} <br />
                {formik.errors.email?.toString()} <br />
                {formik.errors.password?.toString()} <br />
                {formik.errors.confirmpassword?.toString()} <br />
                {formik.errors.acceptTerms?.toString()} <br />
            </Alert> */}

            {/* begin::Form group Firstname */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                <input
                    placeholder='Name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.name && formik.errors.name,
                        },
                        {
                            'is-valid': formik.touched.name && !formik.errors.name,
                        }
                    )}
                />
                {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Email */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            placeholder='Password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('confirmpassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid':
                                formik.touched.confirmpassword && formik.errors.confirmpassword,
                        },
                        {
                            'is-valid':
                                formik.touched.confirmpassword && !formik.errors.confirmpassword,
                        }
                    )}
                />
                {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmpassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='kt_login_toc_agree'
                        {...formik.getFieldProps('acceptTerms')}
                    />
                    <span>
                        I Accept the{' '}
                        <a href='#' target='_blank' className='ms-1 link-primary'>
                            Terms
                        </a>
                        .
                    </span>
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.acceptTerms}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-5'
                    >
                        Cancel
                    </button>
                </Link>
            </div>
            {/* end::Form group */}
        </form>
    )
}
