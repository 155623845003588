/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import React, { useEffect, useState } from 'react'
import { RankCountType } from '../core/_requests'
import { getPhotoLink } from '../../contact-management/contacts-list/core/_requests'
import clsx from 'clsx'

type Props = {
    title: string
    subtitle?: string
    className: string
    data: RankCountType[]
}

const dataToTR = (data: RankCountType) => {
    const defaultPhoto = toAbsoluteUrl(`/media/avatars/blank.png`)
    return (
        <tr
            className={clsx(
                ' tw-pl-4 tw-rounded'
                // 'hover:tw-bg-gray-600'
            )}
        >
            <td>
                <div className='symbol symbol-50px me-2'>
                    <span className='symbol-label'>
                        <img
                            src={data.photo ? getPhotoLink(data.photo + '') : defaultPhoto}
                            className='h-75 align-self-end'
                            loading='lazy'
                            alt=''
                        />
                    </span>
                </div>
            </td>
            <td>
                {/* <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    
                </a> */}
                {data.name}
                {/* <span className='text-muted fw-semibold d-block'>Successful Fellas</span> */}
            </td>
            <td>
                <span className='text-muted fw-semibold d-block fs-7'>count</span>
                <span className='text-dark fw-bold d-block fs-5'>
                    {data?.count ? data.count : 0}
                </span>
            </td>
            {/* <td className='text-end'>
                <span className='text-primary fs-7 fw-bold'>+28%</span>
            </td> */}
            {/* <td className='text-end'>
                <a href='#' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                </a>
            </td> */}
        </tr>
    )
}

const ContactRank: React.FC<Props> = ({ className, title, subtitle, data }) => {
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>
                        {subtitle ? subtitle : ''}
                    </span>
                </h3>
                {/* <div className='card-toolbar'>
                    <ul className='nav'>
                        <li className='nav-item'>
                            <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_6_tab_1'
                            >
                                Month
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_6_tab_2'
                            >
                                Week
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_6_tab_3'
                            >
                                Day
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                <div className='tab-content'>
                    {/* begin::Tap pane */}
                    <div className='tab-pane fade show active' id='kt_table_widget_6_tab_1'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-150px'></th>
                                        {/* <th className='p-0 min-w-140px'></th>
                                        <th className='p-0 min-w-120px'></th> */}
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody className='tw-overflow-y-scroll tw-max-h-screen '>
                                    {data.length > 0 && data.map((d) => dataToTR(d))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ContactRank }
