import { FC, useState, createContext, useContext, useMemo } from 'react'

import { WithChildren } from '../../../../../../_metronic/helpers'

type ContactImportContextType = {
    contactImport: boolean
    setState: (state: boolean) => void
}

const ContactImportContext = createContext<ContactImportContextType>({
    contactImport: false,
    setState: () => {},
})

const ContactImportProvider: FC<WithChildren> = ({ children }) => {
    const [contactImport, setContactImport] = useState<boolean>(false)

    return (
        <ContactImportContext.Provider
            value={{
                contactImport,
                setState: (state: boolean) => {
                    setContactImport(state)
                },
            }}
        >
            {children}
        </ContactImportContext.Provider>
    )
}

const useContactImport = () => useContext(ContactImportContext)

export { ContactImportProvider, useContactImport }
