/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Tab } from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
    className: string
}

type dataType = {
    check: boolean
    image: string
    name: string
    email: string
    company: string
    companyContact: string
}

const demoData : Array<dataType> = [
    {check: false , image: '' , name: 'Ana Simmons'    , email : 'Ana@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-2.jpg'  , name: 'Jessie Clarcson', email : 'Jessie@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-5.jpg'  , name: 'Lebron Wayde'   , email : 'Lebron@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-20.jpg' , name: 'Natali Goodwin' , email : 'Natali@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-23.jpg' , name: 'Kevin Leonard'  , email : 'Kevin@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-14.jpg' , name: 'Ana Simmons', email : 'Ana@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-14.jpg' , name: 'Ana Simmons', email : 'Ana@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'},
    {check: false , image: '/media/avatars/300-14.jpg' , name: 'Ana Simmons', email : 'Ana@demo.com', company: 'Intertico', companyContact : 'intertico@demo.com'}
]

const TableRow = (data : dataType) => {
    const blank_profile_img = '/media/avatars/blank.png';
    return (
        <tr>
            <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value= { data.check ? '1': '0'}
                    />
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                        <img
                            src={toAbsoluteUrl( (data.image) ? data.image:blank_profile_img )}
                            alt=''
                        />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                        <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary fs-6'
                        >
                            {data.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {data.email}
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <a
                    href='#'
                    className='text-dark fw-bold text-hover-primary d-block fs-6'
                >
                    {data.company}
                </a>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {data.companyContact}
                </span>
            </td>
            {/* <td className='text-end'>
                <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                            50%
                        </span>
                    </div>
                    <div className='progress h-6px w-100'>
                        <div
                            className='progress-bar bg-primary'
                            role='progressbar'
                            style={{width: '50%'}}
                        ></div>
                    </div>
                </div>
            </td> */}
            <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen019.svg'
                            className='svg-icon-3'
                        />
                    </a>
                    <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                        <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                        />
                    </a>
                    <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr082.svg'
                            className='svg-icon-3'
                        />
                    </a>
                </div>
            </td>
        </tr>
    )
}

const TablesWidget10: React.FC<Props> = ({className}) => {
    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Your Contacts</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <a
                        href='#'
                        className='btn btn-sm btn-light-primary'
                        // data-bs-toggle='modal'
                        // data-bs-target='#kt_modal_invite_friends'
                    >
                        <KTSVG
                            path='media/icons/duotune/arrows/arr075.svg'
                            className='svg-icon-3'
                        />
                        New Member
                    </a>
                </div>
            </div>

            <div className='card-body py-3'>
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                        />
                                    </div>
                                </th>
                                <th className='min-w-150px'>Name</th>
                                <th className='min-w-140px'>Company</th>
                                {/* <th className='min-w-120px'>Progress</th> */}
                                <th className='min-w-100px text-end'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {demoData.map(element => TableRow(element))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export {TablesWidget10}
