import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useThemeMode } from '../../../_metronic/partials'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import clsx from 'clsx'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
const ShareContactLayout = () => {
    const { mode } = useThemeMode()
    const isDark = mode === 'dark'
    useEffect(() => {
        BODY_CLASSES.forEach((c) => document.body.classList.add(c))
        // document.body.style.backgroundImage = isDark
        //     ? `url(${toAbsoluteUrl('/media/background/dark.svg')})`
        //     : `url(${toAbsoluteUrl('/media/background/light.svg')})`

        // document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/to1card/to1card.png')})`
        // document.body.style.backgroundPosition = `center`
        // document.body.style.backgroundRepeat = `no-repeat`
        // document.body.style.backgroundSize = `40%`
        // .imageContent{
        //     background: url('http://orig15.deviantart.net/0e45/f/2010/052/1/1/calvin_and_hobbes_2010_by_nami86.png') no-repeat center center;
        //     background-size: auto 100%;
        //   height: 500px;
        //   padding-top: 3%;
        //   padding-bottom: 3%;
        // }
        return () => {
            BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
            document.body.style.backgroundImage = 'none'
        }
    }, [mode])

    return (
        // <div className='d-flex flex-column flex-root min-h-full'>
        //     <div className='d-flex flex-column flex-center flex-column-fluid min-h-full'>
        //         <div className='d-flex flex-column flex-center text-center p-10  min-h-full'>

        //         </div>
        //     </div>
        // </div>
        <div
            className={clsx(
                'tw-flex tw-flex-row tw-justify-center tw-min-h-full '
                // 'tw-bg-gradient-to-br tw-to-background tw-from-indigo-900 tw-from-50%'
            )}
            style={{
                // background:'radial-gradient(circle, rgba(19,16,9,1) 0%, rgba(40,14,74,1) 47%, rgba(59,10,115,1) 100%)',
                background: '#151521    ',
                // backdropFilter: 'blur(20px)',
            }}
        >
            {/* <div className='card card-flush tw-h-10'>dlkasjkdjlkasjdlkjaskljdklsjalkj</div> */}
            <div
                className={clsx(
                    'w-lg-650px tw-min-h-full'
                    // isDark
                    //     ? ' bg-gradient-to-r from-violet-500 to-fuchsia-500'
                    //     : ' bg-gradient-to-r from-violet-500 to-fuchsia-500'
                )}
            >
                <div
                    className='tw-min-h-full'
                    // style={{
                    //     background: 'transparent',
                    // }}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export { ShareContactLayout }
