import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/user`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const REFRESH_URL = `${API_URL}/auth/refresh`

// Server should return AuthModel
export function login(email: string, password: string) {
    console.log(LOGIN_URL)
    return axios.post(LOGIN_URL, {
        email,
        password,
    })
}

// Server should return AuthModel
export function register(
    name: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        name,
        email,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function getNewAccessToken(refreshToken: string) {
    return axios.post<string>(REFRESH_URL, { refreshToken: refreshToken }).then((d) => d.data)
}

export function getUserByToken() {
    return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
