/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import clsx from 'clsx'
import { useThemeMode } from '../../../../../_metronic/partials'
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../../_metronic/helpers'
import { VisitCountType } from '../core/_requests'

type Props = {
    className: string
    svgIcon?: string
    color: string
    data: VisitCountType[]
    change?: string
    description: string
}

export const VisitCountMonth: React.FC<Props> = ({
    className,
    svgIcon,
    data,
    color,
    change,
    description,
}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    // const [data, setData] = useState<VisitCountType[]>([])
    // const [loaded, setLoaded] = useState<boolean>(false)
    const { mode } = useThemeMode()

    const labelColor = getCSSVariableValue('--kt-gray-800')
    const baseColor = getCSSVariableValue('--kt-' + color)
    const lightColor = getCSSVariableValue('--kt-' + color + '-light')

    const height = 150

    const dates = data.map((d) => d.date).reverse()
    const counts = data.map((d) => d.count).reverse()
    const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(dates, counts, height, labelColor, baseColor, lightColor)
    )

    if (chart) {
        chart.render()
    }

    console.log(data)
    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
                <div className='d-flex flex-stack card-p flex-grow-1'>
                    {/* <span className={clsx('symbol symbol-50px', `symbol-light-${color}`, 'me-2')}>
                        <span className='symbol-label'>
                            <KTSVG path={svgIcon} className={`svg-icon-2x svg-icon-${color}`} />
                        </span>
                    </span> */}

                    <div className='d-flex flex-column text-start'>
                        <span className='text-dark fw-bold fs-2'>{description}</span>

                        <span className='text-muted fw-semibold mt-1'>{` Counts : ${
                            data[0] ? data[0].count : 0
                        }`}</span>
                    </div>
                </div>

                <div
                    ref={chartRef}
                    className='card-rounded-bottom'
                    style={{ height: '150px' }}
                ></div>
            </div>
            {/* end::Body */}
        </div>
    )
}

function getChartOptions(
    date: string[],
    count: number[],
    height: number,
    labelColor: string,
    baseColor: string,
    lightColor: string
): ApexOptions {
    console.log(date)
    console.log(count)
    return {
        series: [
            {
                name: 'Count',
                data: count,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: height,
            width: '100%',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor],
        },
        xaxis: {
            categories: date,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: '#E4E6EF',
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            // min: 0,
            // max: 60,
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return '' + val + ' peoples'
                },
            },
        },
        colors: [lightColor],
        markers: {
            colors: [lightColor],
            strokeColors: [baseColor],
            strokeWidth: 3,
        },
    }
}
